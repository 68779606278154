import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _67873d52 = () => interopDefault(import('..\\pages\\account\\index.vue' /* webpackChunkName: "pages/account/index" */))
const _3d86b05c = () => interopDefault(import('..\\pages\\childTab\\index.vue' /* webpackChunkName: "pages/childTab/index" */))
const _536be415 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _4c20c12b = () => interopDefault(import('..\\pages\\list.vue' /* webpackChunkName: "pages/list" */))
const _1d13872c = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _61d03230 = () => interopDefault(import('..\\pages\\register.vue' /* webpackChunkName: "pages/register" */))
const _07a35bdc = () => interopDefault(import('..\\pages\\reset.vue' /* webpackChunkName: "pages/reset" */))
const _74611016 = () => interopDefault(import('..\\pages\\search.vue' /* webpackChunkName: "pages/search" */))
const _180fec0a = () => interopDefault(import('..\\pages\\survey\\index.vue' /* webpackChunkName: "pages/survey/index" */))
const _1870c85a = () => interopDefault(import('..\\pages\\member\\certSearch.vue' /* webpackChunkName: "pages/member/certSearch" */))
const _b19dcc76 = () => interopDefault(import('..\\pages\\member\\list.vue' /* webpackChunkName: "pages/member/list" */))
const _2047987a = () => interopDefault(import('..\\pages\\submenu\\_id\\index.vue' /* webpackChunkName: "pages/submenu/_id/index" */))
const _6a2c643a = () => interopDefault(import('..\\pages\\submenu\\_id\\subarticle\\_subId.vue' /* webpackChunkName: "pages/submenu/_id/subarticle/_subId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _67873d52,
    name: "account___zhObj"
  }, {
    path: "/childTab",
    component: _3d86b05c,
    name: "childTab___zhObj"
  }, {
    path: "/enObj",
    component: _536be415,
    name: "index___enObj"
  }, {
    path: "/list",
    component: _4c20c12b,
    name: "list___zhObj"
  }, {
    path: "/login",
    component: _1d13872c,
    name: "login___zhObj"
  }, {
    path: "/register",
    component: _61d03230,
    name: "register___zhObj"
  }, {
    path: "/register",
    component: _61d03230,
    name: "register___zhObj"
  }, {
    path: "/reset",
    component: _07a35bdc,
    name: "reset___zhObj"
  }, {
    path: "/search",
    component: _74611016,
    name: "search___zhObj"
  }, {
    path: "/survey",
    component: _180fec0a,
    name: "survey___zhObj"
  }, {
    path: "/enObj/account",
    component: _67873d52,
    name: "account___enObj"
  }, {
    path: "/enObj/childTab",
    component: _3d86b05c,
    name: "childTab___enObj"
  }, {
    path: "/enObj/list",
    component: _4c20c12b,
    name: "list___enObj"
  }, {
    path: "/enObj/login",
    component: _1d13872c,
    name: "login___enObj"
  }, {
    path: "/enObj/register",
    component: _61d03230,
    name: "register___enObj"
  }, {
    path: "/enObj/register",
    component: _61d03230,
    name: "register___enObj"
  }, {
    path: "/enObj/reset",
    component: _07a35bdc,
    name: "reset___enObj"
  }, {
    path: "/enObj/search",
    component: _74611016,
    name: "search___enObj"
  }, {
    path: "/enObj/survey",
    component: _180fec0a,
    name: "survey___enObj"
  }, {
    path: "/member/certSearch",
    component: _1870c85a,
    name: "member-certSearch___zhObj"
  }, {
    path: "/member/list",
    component: _b19dcc76,
    name: "member-list___zhObj"
  }, {
    path: "/enObj/member/certSearch",
    component: _1870c85a,
    name: "member-certSearch___enObj"
  }, {
    path: "/enObj/member/list",
    component: _b19dcc76,
    name: "member-list___enObj"
  }, {
    path: "/",
    component: _536be415,
    name: "index___zhObj"
  }, {
    path: "/enObj/submenu/:id",
    component: _2047987a,
    name: "submenu-id___enObj"
  }, {
    path: "/enObj/submenu/:id?/subarticle/:subId?",
    component: _6a2c643a,
    name: "submenu-id-subarticle-subId___enObj"
  }, {
    path: "/submenu/:id",
    component: _2047987a,
    name: "submenu-id___zhObj"
  }, {
    path: "/submenu/:id?/subarticle/:subId?",
    component: _6a2c643a,
    name: "submenu-id-subarticle-subId___zhObj"
  }, {
    path: "/enObj*",
    component: _536be415,
    name: "custom___enObj"
  }, {
    path: "*",
    component: _536be415,
    name: "custom___zhObj"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
