import http from './method.js'

// 分类列表
export const categoryList = () => {
  return http().get('/course/api/category/list')
}
// 首页推荐课程
export const zoneList = (params = {}) => {
  return http().post('/course/api/zone/list', params)
}
// 站点信息
export const websiteInfo = (req) => {
  // return http().get('/system/api/sys/config/website')
  return http('', {}, req).get('/api/system/tyWbeSite/item/index')
}
// 首页导航栏
export const navList = (req) => {
  // return http().get('/system/api/website/nav/list')
  // eslint-disable-next-line no-return-assign
  return http('', {}, req).get('/api/system/tyWbeSite/getCate')
}
// 首页轮播图
// export const carouselList = () => {
//   return http().get('/system/api/website/carousel/list')
// }
export const carouselList = (req) => {
  return http('', {}, req).get('/api/system/tyWbeSite/getCarousel')
}

export const getArticles = (param, req) => {
  return http('', {}, req).get(`/api/system/tyWbeSite/getArticles/${param}`)
}

// export const getArticles = (param, req) => {
//   return http('', {}, req).get(`/api/system/tyWbeSite/getArticles/${param}`)
//     .then(response => {
//       if (response&& Array.isArray(response)) {
//         // 对返回的数据进行排序
//         response.sort((a, b) => {
//           // 1. 首先按 `sort` 字段升序排列
//           if (a.sort !== b.sort) {
//             return a.sort - b.sort;
//           }
//           // 2. 如果 `sort` 相同，则按 `createTime` 离当前时间的差值进行升序排列
//           const currentTime = new Date();
//           const aTimeDiff = Math.abs(currentTime - new Date(a.createTime));
//           const bTimeDiff = Math.abs(currentTime - new Date(b.createTime));
//           return aTimeDiff - bTimeDiff;
//         });
//       }
//       // 返回排序后的数据
//       return response;
//     })
//     .catch(error => {
//       console.error('获取数据失败', error);
//       throw error;  // 如果有错误，抛出错误
//     });
// }

export const getArticlePage = (param, num) => {
  return http().get(`/api/system/tyWbeSite/getArticle/${param}/${num}`)
}

// export const getArticlePage = (param, num) => {
//   return http().get(`/api/system/tyWbeSite/getArticle/${param}/${num}`)
//     .then(response => {
//       if (response && response.records) {
//         const currentTime = new Date().getTime(); // 获取当前时间戳

//         // 对 records 进行排序
//         const sortedRecords = response.records.sort((a, b) => {
//           // 先按 sort 升序排列
//           if (a.sort !== b.sort) {
//             return a.sort - b.sort;
//           }

//           // 如果 sort 相同，按时间离当前时间的距离排序
//           const timeA = new Date(a.createTime).getTime();
//           const timeB = new Date(b.createTime).getTime();

//           return Math.abs(currentTime - timeA) - Math.abs(currentTime - timeB);
//         });

//         // 返回处理后的数据，保持原有格式
//         return {
//           ...response,
//           records: sortedRecords
//         };
//       }

//       // 如果没有数据，直接返回原始响应
//       return response;
//     })
//     .catch(error => {
//       console.error('请求出错', error);
//       throw error;  // 错误处理
//     });
// }

export const getArticleInfo = (cateId, articleId) => {
  return http().get(`/api/system/tyWbeSite/getArticleInfo/${cateId}/${articleId}`)
}
// 友情链接
export const linkList = () => {
  return http().get('/api/system/tyWbeSite/link/index')
}
// 邮件重置密码
export const resetPwdByemail = (email) => {
  return http().get('/api/system/email/send?to=' + email)
}
